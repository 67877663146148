import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import ClipboardCopy from '../widgets/clipboard_copy'
import UserInfoModal from '../coupons/user_info_modal'


const URL_PROFILE_TYPE = {
  person: 'people',
  company: 'company'
}

const RequestCard = (props) => {

  const { action_type, created_on, profile, type, user_details, list,
    mode, started_on, status, chrome_extension_version } = props.reqInfo
  const { name, email, tags, user_id } = user_details


  const onClickListName = () => {
    props.history.push({
      pathname: '/dashboard/clearout_prospect/lists',
      search: `?name=${list.name}`
    })
  }

  const getContent = () => {
    switch (action_type) {
      case 'add_and_enrich_lead':
      case 'enrich_lead':
        return (
          <div
            className='d-flex flex-column justify-content-between'
            style={{ padding: '6px 12px 6px 0' }}
          >
            <div className='d-flex justify-content-between'>
              <span style={{ flex: '0 1 70%' }}>
                {_.startCase(type)}&nbsp;
                <strong
                  style={{ cursor: 'pointer' }}
                >
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={CLEAROUT_APP_URL + `dashboard/prospect/${URL_PROFILE_TYPE[type]}?id=${profile.id}`}
                    className='text-decoration-none'
                  >
                    {profile.name}
                  </a>
                </strong>
                &nbsp;is being enriched&nbsp;
              </span>
              <span>
                {moment(created_on).fromNow()}
              </span>
            </div>
            <div className='d-flex justify-content-between file-uploaded-date'>
              <span style={{ flex: '0 1 70%' }}>
                Started On:&nbsp;{moment(started_on).format('ddd, MMM Do YYYY, h:mm:ss a')}
              </span>
              <span>
                {_.startCase(status)}
              </span>
            </div>
            <div className='d-flex justify-content-between file-uploaded-date'>
              <span style={{ flex: '0 1 70%' }}>
                Mode:&nbsp;{mode}
              </span>
              <span>
                {chrome_extension_version}
              </span>
            </div>
          </div >
        )

      case 'enrich_lead_list':
        return (
          <div
            className='d-flex flex-column justify-content-between'
            style={{ padding: '6px 12px 6px 0' }}
          >
            <div className='d-flex justify-content-between'>
              <span style={{ flex: '0 1 70%' }}>
                {_.startCase(type)} list&nbsp;
                <strong
                  style={{ cursor: 'pointer' }}
                  onClick={onClickListName}
                >
                  {list.name}
                </strong>
                &nbsp;is being enriched&nbsp;
              </span>
              <span>
                {moment(created_on).fromNow()}
              </span>
            </div>
            <div className='d-flex justify-content-between file-uploaded-date'>
              <span style={{ flex: '0 1 70%' }}>
                Started On:&nbsp;{started_on}
              </span>
              <span>
                {_.startCase(status)}
              </span>
            </div>
          </div>
        )

      case 'download_as_csv':
        return (
          <div
            className='d-flex flex-column justify-content-between'
            style={{ padding: '6px 12px 6px 0' }}
          >
            <div className='d-flex justify-content-between'>
              <span
                style={
                  { flex: '0 1 70%' }}
              >
                {_.startCase(type)} list&nbsp;
                <strong
                  style={{ cursor: 'pointer' }}
                  onClick={onClickListName}
                >
                  {list.name}
                </strong>
                &nbsp;is being exporting to csv&nbsp;
              </span>
              <span>
                {moment(created_on).fromNow()}
              </span>
            </div>
            <div className='d-flex justify-content-between file-uploaded-date'>
              <span style={{ flex: '0 1 70%' }}>
                Started On:&nbsp;{started_on}
              </span>
              <span>
                {_.startCase(status)}
              </span>
            </div>
          </div>
        )

      case 'export_to_google_sheet':
        return (
          <div
            className='d-flex flex-column justify-content-between'
            style={{ padding: '6px 12px 6px 0' }}
          >
            <div className='d-flex justify-content-between'>
              <span
                style={
                  { flex: '0 1 70%' }}
              >
                {_.startCase(type)} list&nbsp;
                <strong
                  style={{ cursor: 'pointer' }}
                  onClick={onClickListName}
                >
                  {list.name}
                </strong>
                &nbsp;is being exporting to csv&nbsp;
              </span>
              <span>
                {moment(created_on).fromNow()}
              </span>
            </div>
            <div className='d-flex justify-content-between file-uploaded-date'>
              <span style={{ flex: '0 1 70%' }}>
                Started On:&nbsp;{started_on}
              </span>
              <span>
                {_.startCase(status)}
              </span>
            </div>
          </div>
        )

      default: return null
    }
  }

  return (
    <>
      <div
        style={{
          background: '#ecf0f5', padding: '1rem',
          borderRadius: '0.5rem'
        }}
      >
        {getContent()}
        <p
          className='file-uploaded-date'
        >
          Name:&nbsp;{name}
        </p>
        <div className='d-flex align-items-center'>
          <p className='file-uploaded-date'>
            Email:&nbsp;
            {email}
            <UserInfoModal userId={user_id} />
          </p>&nbsp;&nbsp;
          <ClipboardCopy
            textToCopy={email}
          />
        </div>
        <p className='paid-label'>
          {
            !_.isEmpty(tags)
              ? <span className=' label label-success'>Paid</span>
              : null
          }
        </p>
      </div>
    </>
  )
}

export default RequestCard